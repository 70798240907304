.u_mobile-home-logo {
  width: 100%;
  @include for-breakpoint(6) {
    display: none;
  }
  > img {
    display: block;
    width: 100%;
  }
}
