html {
  font-size: 62.5%;

  // http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
}

@font-face {
  font-family: 'CGR-TradeGothicLT';
  src: url('#{$webfontsFolder}/3395C7_0_0.woff2') format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'CGR-TradeGothicLT';
  src: url('#{$webfontsFolder}/3395C7_1_0.woff2') format('woff2');
  font-style: italic;
}
