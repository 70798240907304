.u_mobile-office-image {
  max-width: 100%;
  position: relative;
  @include for-breakpoint(6) {
    display: none;
  }
  > div.text {
    position: absolute;
    color: $color3;
    top: 18%;
    left: 24%;
    > span {
      display: block;
      font-size: 3.7vw;
      line-height: 4.8vw;
      //@include rem( 'font-size', 22px);
      //@include rem( 'line-height', 26px);
      &.first {
        padding-left: 2.5vw;
      }
      &.second {
        padding-left: 4.5vw;
      }
      &.third {

      }
    }
  }
  > img {
    display: block;
    width: 100%;
  }
}
