.u_about-info {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
    display: block;
  }
  > div {
    display: block;
    padding: $mobile-margin;
    width: 100%;
    @include for-breakpoint(6) {
      color: $color3;
      position: absolute;
    }
    a {
      color: $color1;
      text-decoration: underline;
      transition: color $transTime ease;
      @include for-breakpoint(6) {
        color: $color3;
      }
      &:hover {
        color: $color2;
        @include for-breakpoint(6) {
          color: lighten($color2, 15%);
        }
      }
    }
    > p {
      margin-bottom: 10px;
    }
    > ul {
      > li {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 5px;
      }
    }
  }
  > div.about-info {
    padding-bottom: 0px;
    @include for-breakpoint(6) {
      background-color: transparentize($color4, 0.35);
      height: 100%;
      width: 100%;
      top: 0px;
      bottom: 0px;
      left: 0px;
      text-align: left;
      @include rem("font-size", 18px);
      @include rem("line-height", 20px);
    }
  }
}
