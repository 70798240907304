.u_internal-parent-link {
  @include internalNav();
  text-decoration: none;
  > h2 {
    border-bottom-width: 0px;
  }
}

.u_internal-parent-link.u_internal-parent-link__single-title {
  > h2 {
    border-bottom-width: 2px;
  }
}
