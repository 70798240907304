@mixin selected-nav-item() {
  @include for-breakpoint(6) {
    border-bottom-width: $underlineWidth;
    border-bottom-color: $color1;
    margin-bottom: 0;
    color: $color1;
  }
}

.u_main-nav {
  > ul {
    background-color: $color3;
    max-height: 0;
    overflow: hidden;
    transition: max-height $transTime ease;
    @include for-breakpoint(6) {
      max-height: none;
      display: flex;
      justify-content: flex-end;
      margin-right: $desktop-margin;
    }

    > li {
      display: block;
      @include for-breakpoint(6) {
        margin-bottom: 28px;
        margin-left: 55px;
      }

      > a {
        display: block;
        padding: 16px $mobile-margin;
        border-color: $color1;
        border-style: solid;
        border-top-width: 2px;
        @include rem("font-size", 18px);
        @include rem("line-height", 18px);
        text-transform: uppercase;
        color: $color2;
        text-decoration: none;
        transition: color $transTime ease;
        font-weight: bold;
        @include for-breakpoint(6) {
          border: none;
          border-bottom-style: solid;
          border-bottom-color: transparent;
          border-bottom-width: 0;
          margin-bottom: $underlineWidth;
          padding: 0 0 1px;
          margin-bottom: 3px;
          transition: color $transTime ease, border-bottom-color $transTime ease;
        }

        &:hover {
          color: $color1;
          @include for-breakpoint(6) {
            border-bottom-width: $underlineWidth;
            border-bottom-color: $color1;
            margin-bottom: 0;
          }
        }
      }
      &:last-child {
        > a {
          border-bottom-width: 1px;
          @include for-breakpoint(6) {
            border-bottom-width: 0;
          }
          &:hover {
            @include for-breakpoint(6) {
              border-bottom-width: $underlineWidth;
              border-bottom-color: $color1;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.isOpen {
    > ul {
      max-height: 160px;
      @include for-breakpoint(6) {
        max-height: none;
      }
    }
  }
  &.hasSelectedAttorneys {
    > ul {
      > li.attorneys {
        > a {
          @include selected-nav-item();
        }
      }
    }
  }
  &.hasSelectedAreas {
    > ul {
      > li.areas {
        > a {
          @include selected-nav-item();
        }
      }
    }
  }
  &.hasSelectedContact {
    > ul {
      > li.contact {
        > a {
          @include selected-nav-item();
        }
      }
    }
  }
  &.hasSelectedAbout {
    > ul {
      > li.about {
        > a {
          @include selected-nav-item();
        }
      }
    }
  }
  &.isHomepage {
    @include for-breakpoint(6) {
      opacity: 0;
      transition: opacity $uncoverTransition ease;
      transition-delay: $animationTransition;
    }
  }
  &.isUncovered {
    @include for-breakpoint(6) {
      opacity: 1;
    }
  }
  &.isAnimationSkipped {
    @include for-breakpoint(6) {
      transition-delay: 0ms;
      transition-duration: 0ms;
    }
  }
}
