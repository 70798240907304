.u_news-item-left-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  max-height: $wrapperHeight;
  overflow: auto;
  @include for-breakpoint(6) {
    text-align: right;
    padding-top: 15px;
  }
  > h3.title {
    display: inline-block;
    text-decoration: none;
    color: $color2;
    @include rem("font-size", 19px);
    @include rem("line-height", 21px);
    margin-left: $mobile-margin;
    margin-bottom: 15px;
    border-bottom-width: $underlineWidth;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    transition: border-color $transTime ease, color $transTime ease;
    @include for-breakpoint(6) {
      display: block;
      @include rem("font-size", 18px);
      @include rem("line-height", 17px);
      padding-bottom: 1px;
      margin-left: 0;
    }
  }
}
