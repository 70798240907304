.u_news-item-content {
  margin: $mobile-margin;
  overflow: hidden;
  @include for-breakpoint(6) {
    margin: 15px;
    display: block;
    flex-wrap: wrap;
  }

  > h3.title {
    display: none;
    @include for-breakpoint(6) {
      display: block;
      font-size: 1.8rem;
      line-height: 1.5em;
      margin-bottom: 5px;
    }
  }

  > div.date,
  > div.content {
    font-size: 1.8rem;
    line-height: 1.5em;
    > h1, h2, h3, h4, h5 {
      margin-top: 30px;
      margin-bottom: 20px;
    }
    > p {
      margin-bottom: 20px;
      color: $color4;

      strong {
        color: $color1;
        font-weight: normal;
      }

      a {
        color: $color4;
      }

      em {
        font-style: italic;
      }
    }
  }
  > div.date {
    margin-bottom: 20px;
    color: $color4;
    font-size: 1.6rem;
  }
}
