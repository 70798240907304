.u_contact-info {
  position: relative;
  width: 100%;
  max-height: 496px;
  > img {
    width: 100%;
    display: block;
  }
  > div {
    display: block;
    padding: $mobile-margin;
    width: 100%;
    @include for-breakpoint(6) {
      color: $color3;
      position: absolute;
    }
    > a {
      color: $color1;
      text-decoration: none;
      transition: color $transTime ease;
      @include for-breakpoint(6) {
        color: $color3;
      }
      &:hover {
        color: $color2;
        @include for-breakpoint(6) {
          color: darken($color3, 15%);
        }
      }
    }
  }
  > div.contact-info {
    padding-bottom: 0px;
    @include for-breakpoint(6) {
      width: 300px;
      bottom: $desktop-margin;
      right: $desktop-margin;
      text-align: right;
      @include rem("font-size", 18px);
      @include rem("line-height", 20px);
    }
  }
  > div.credits {
    @include rem("font-size", 10px);
    @include rem("line-height", 13px);
    @include for-breakpoint(6) {
      @include rem("font-size", 12px);
      @include rem("line-height", 15px);
      text-align: right;
      bottom: 0;
      right: 0;
      padding-right: $desktop-margin;
    }
  }
}
