$logoHeight: 35px;
$logoVMargin: 8px;

.u_main-header {
  position: fixed;
  background-color: $color3;
  z-index: z('above-content') - 1;
  width: 100%;
  @include for-breakpoint(6) {
    position: relative;
  }

  > div.wrapper {
    max-width: $max-width;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @include for-breakpoint(6) {
      align-items: flex-end;
    }

    > div.logo-container {
      flex-basis: calc(100% - #{(30px + ($mobile-margin * 1.5))});
      @include for-breakpoint(6) {
        flex-basis: 300px;
      }

      > h1 {
        margin-left: $mobile-margin;
        margin-top: $logoVMargin;
        margin-bottom: $logoVMargin;
        @include for-breakpoint(6) {
          margin-top: 25px;
          margin-bottom: 25px;
          margin-left: $desktop-margin;
        }

        > a {
          display: block;
          width: 70px;
          height: $logoHeight;
          display: block;
          background-image: url('#{$imgsFolder}/logo.svg');
          background-repeat: no-repeat;
          background-size: contain;
          text-indent: -10000px;
          @include for-breakpoint(6) {
            width: 220px;
            height: 110px;
          }

        }
      }
    }

    > div.nav-button-container {
      margin-right: $mobile-margin*1.5;
      flex-basis: 30px;
      display: flex;
      align-items: center;
      @include for-breakpoint(6) {
        display: none;
      }
    }

    > div.nav-container {
      position: absolute;
      top: $logoHeight + $logoVMargin*2;
      left: 0;
      display: block;
      width: 100%;
      flex-basis: 100%;
      z-index: z('above-content');
      @include for-breakpoint(6) {
        flex-basis: calc(100% - 300px);
        position: relative;
        top: auto;
        left: auto;
      }
    }
  }

  &.isHomepage {
    > div.wrapper {
      > div.logo-container {
        opacity: 0;
        transition: opacity $uncoverTransition ease;
        transition-delay: $animationTransition;
        > h1 {
          > a {
            cursor: default;
            pointer-events: none;
          }
        }
      }
    }
  }

  &.isUncovered {
    > div.wrapper {
      > div.logo-container {
        @include for-breakpoint(6) {
          opacity: 1;
        }
      }
    }
  }
  &.isAnimationSkipped {
    > div.wrapper {
      > div.logo-container {
        @include for-breakpoint(6) {
          transition-delay: 0ms;
          transition-duration: 0ms;
        }
      }
    }
  }
}
