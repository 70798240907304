.u_section-image {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > span {
    display: block;
    order: 2;
    padding: $mobile-margin;
    @include for-breakpoint(6) {
      position: absolute;
      width: 100%;
      color: $color3;
      padding: 15px;
      @include rem('font-size', 18px);
      @include rem('line-height', 20px);
    }
    > br {
      display: none;
      @include for-breakpoint(6) {
        display: inline;
      }
    }
  }
  > img {
    order: 1;
    width: 100%;
    display: block;
    height: 100%;
  }
}
