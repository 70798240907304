.u_body {
  box-sizing: border-box;
  color: $color1;
  font-family: $font-family;
  @include rem('font-size',17px);
  @include rem('line-height',24px);
  @include for-breakpoint(6) {
    @include rem('font-size',13px);
    @include rem('line-height',17px);
  }
  & * {
    box-sizing: border-box;
  }
}
