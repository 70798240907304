$mainAreaMinHeight: 496px;

.u_main-content {
  padding-top: 51px; // height of header, should be a variable
  @include for-breakpoint(6) {
    border-top: 8px solid $color1;
    border-bottom: 8px solid $color1;
    padding-top: 0;
  }
  > div.wrapper {
    position: relative;
    max-width: $max-width;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    @include for-breakpoint(6) {
      //min-height: $mainAreaMinHeight;
      flex-wrap: nowrap;
    }
    > aside.left-column {
      display: none;
      @include for-breakpoint(6) {
        display: block;
        flex-basis: $leftColWidth;
        flex-shrink: 0;
        flex-grow: 0;
        padding-left: $desktop-margin;
      }
    }
    > section.right-column {
      display: block;
      flex-basis: 100%;
      @include for-breakpoint(6) {
        display: block;
        border-left: 8px solid $color1;
        max-width: calc(100% - #{$leftColWidth + 8px});
        flex-basis: calc(100% - #{$leftColWidth + 8px});
        flex-shrink: 0;
        flex-grow: 0;
        @include for-edge() {
          width: calc(100% - #{$leftColWidth + 8px});
        }
      }
    }
  }
  &.isSectionLandingPage {
    > div.wrapper {
      @include for-breakpoint(6) {
        min-height: $wrapperHeight;
      }
      > aside.left-column {
        display: block;
        order: 2;
        @include for-breakpoint(6) {
          order: 1;
        }
      }
      > section.right-column {
        order: 1;
        @include for-breakpoint(6) {
          order: 2;
        }
      }
    }
  }
  &.isAttorneyInternal {
    > div.wrapper {
      @include for-breakpoint(6) {
        min-height: 520px;
      }
      > aside.left-column,
      > section.right-column {
        @include for-breakpoint(6) {
          @include for-ie11() {
            min-height: 520px;
          }
        }
      }
    }
  }
  &.isAreaInternal {
    > div.wrapper {
      @include for-breakpoint(6) {
        min-height: 410px;
      }
    }
  }
}
