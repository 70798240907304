.u_practice-area {
  margin: $mobile-margin;
  overflow: hidden;
  @include for-breakpoint(6) {
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
  }

   > div.col2,
   > div.intro,
  > div.col1 {
    > p {
      margin-bottom: 20px;
      color: $color4;

      strong {
        color: $color1;
        font-weight: normal;
      }

      a {
        color: $color4;
      }

      em {
        font-style: italic;
      }
    }
  }

  > div.intro {
    @include for-breakpoint(6) {
      flex-basis: 100%;
      @include rem('font-size',18px);
      @include rem('line-height',20px);
    }
  }

   > div.col2,
  > div.col1 {
    color: $color4;
    @include for-breakpoint(6) {
      padding-right: 10px;
      flex-basis: calc(50% - 10px);
    }
  }

  > div.col2 {
    color: $color4;
    @include for-breakpoint(6) {
      padding-right: 0;
      padding-left: 10px;
    }
  }
}
