@mixin hasSelectedAttorney($attorney) {
  &.hasSelected#{$attorney} {
    > ul > li.#{to-lower-case($attorney)} > a {
      color: $color1;
      border-bottom-color: $color1;
    }
  }
}

.u_attorneys-subnav {
  @include internalSubNav();
  @include hasSelectedAttorney("Clarick");
  @include hasSelectedAttorney("Gueron");
  @include hasSelectedAttorney("Reisbaum");
  @include hasSelectedAttorney("Crowell");
  @include hasSelectedAttorney("Hall");
  @include hasSelectedAttorney("Zaur");
  @include hasSelectedAttorney("Blain");
  @include hasSelectedAttorney("Klein");
  @include hasSelectedAttorney("Kimball");
  @include hasSelectedAttorney("Kumagai");
  @include hasSelectedAttorney("Holsinger");
  @include hasSelectedAttorney("Niedzielski");
  @include hasSelectedAttorney("Vanamali");
  @include hasSelectedAttorney("Woodruff");
  @include hasSelectedAttorney("Wong");
  @include hasSelectedAttorney("Zeisler");
  @include hasSelectedAttorney("Bernstein");
  @include hasSelectedAttorney("Broeksmit");
}
