$homeAnimBarHeight: 160px;
$homeAnimBorderHeight: 8px;

.u_desktop-home-animation {
  display: none;
  position: relative;
  @include for-breakpoint(6) {
    display: block;
  }

  > div.animated-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: z('above-content');

    > span {
      display: block;
      width: 100%;
      height: $homeAnimBarHeight;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: $color3;
      transition: transform $animationTransition ease-in;

      &.top {
        background-image: url('#{$imgsFolder}/logo-clarick.svg');
      }

      &.mid {
        background-image: url('#{$imgsFolder}/logo-gueron.svg');
      }

      &.bottom {
        background-image: url('#{$imgsFolder}/logo-reisbaum.svg');
        border-bottom: none;
      }
    }
    > hr {
      border: none;
      border-bottom: $homeAnimBorderHeight solid $color1;
      height: 0px;
      width: 100%;
      margin: 0;
      transition: opacity $uncoverTransition ease;
      transition-delay: $animationTransition
    }
  }

  > div.office-image {
    overflow: hidden;
    max-height: ($homeAnimBorderHeight * 2) + ($homeAnimBarHeight * 3);
    > div.text {
      position: absolute;
      color: $color3;
      top: 37%;
      left: 49%;
      @include rem('font-size',22px);
      @include rem('line-height',25px);
      > span {
        display: block;
        &.first {
          padding-left: 12px;
        }
        &.second {
          padding-left: 24px;
        }
      }
    }
    > img {
      display: block;
      width: 100%;
    }
  }

  &.isUncovered {
    > div.animated-logo {
      > span {
        &.top {
          transform: translateX(-100%);
        }

        &.mid {
          transform: translateX(100%);
        }

        &.bottom {
          transform: translateX(-100%);
        }
      }
      > hr {
        opacity: 0;
      }
    }
  }
  &.isAnimationSkipped {
    > div.animated-logo {
      > span {
        &.top {
          transition-delay: 0ms;
          transition-duration: 0ms;
        }

        &.mid {
          transition-delay: 0ms;
          transition-duration: 0ms;
        }

        &.bottom {
          transition-delay: 0ms;
          transition-duration: 0ms;
        }
      }
      > hr {
        transition-delay: 0ms;
        transition-duration: 0ms;
      }
    }
  }
}
