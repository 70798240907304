$desktopColWidth: 365.5px;
$desktopColRightPadding: 20px;

@mixin paging() {
  @for $i from 0 through 10 {
    &.isOnPage-#{$i} {
      transform: translateX(#{-1 * $i * ($desktopColWidth) * 2});
    }
  }
}

.u_attorney-bio {
  margin: $mobile-margin;
  overflow: hidden;
  @include for-breakpoint(6) {
    margin: 15px;
  }

  > div.columns {
    display: inline;
    @include for-breakpoint(6) {
      display: flex;
      transition: transform $columnAnimationTransition ease;
      @include paging();
    }

    > div.base-content {
      display: none;
    }

    > div.column {
      color: $color4;
      max-width: 580px;

      @include for-breakpoint(6) {
        flex-basis: $desktopColWidth;
        flex-shrink: 0;
        flex-grow: 0;
        padding-right: $desktopColRightPadding;
        min-height: 520px;
        @include for-ie11() {
          flex-basis: $desktopColWidth - 20px;
        }
      }

      > img.bio-image {
        width: 100%;
        max-width: 265px;
        margin-bottom: 20px;
        @include for-breakpoint(6) {
          width: 100%;
          max-width: none;
        }
      }

      > h3 {
        text-transform: uppercase;
        @include rem("font-size", 15px);
        @include rem("line-height", 17px);
        color: $color1;
      }

      > span.type {
        display: block;
        color: $color1;
        text-transform: capitalize;
      }

      > h4 {
        color: $color1;
        text-transform: uppercase;
      }

      > a.phone {
        display: block;
        color: $color1;
        text-decoration: none;
      }

      > a.email {
        display: block;
        color: $color1;
        transition: color $transTime ease;
        text-decoration: none;
        &:hover {
          color: $color2;
        }
      }

      > a.download-vcard {
        display: block;
        margin-bottom: 20px;
        color: $color1;
        text-decoration: none;
        transition: color $transTime ease;

        &:hover {
          color: $color2;
        }
      }

      > div.more-btn {
        @include button-to-anchor();
        display: none;
        @include for-breakpoint(6) {
          display: block;
        }

        > button {
          @include button-to-anchor();
          display: inline-block;
          color: $color2;
          text-transform: uppercase;
          font-family: $font-family;
          transition: color $transTime ease;
          &:before,
          &:after {
            top: -1px;
            transition: border-color $transTime ease;
          }
        }

        > button.prev {
          &:before {
            @include triangle(left, $color2, 5px);
            margin-right: 3px;
          }
          &:hover {
            color: $color1;
            &:before {
              border-#{opposite-direction(left)}-color: $color1;
            }
          }
        }

        > button.next {
          &:after {
            @include triangle(right, $color2, 5px);
            margin-left: 3px;
          }
          &:hover {
            color: $color1;
            &:after {
              border-#{opposite-direction(right)}-color: $color1;
            }
          }
        }
      }

      > p {
        margin-bottom: 20px;
        a {
          color: $color4;
        }
        em {
          font-style: italic;
        }
        strong {
          font-weight: bold;
        }
      }

      > hr {
        display: none;
      }
    }
  }
}
