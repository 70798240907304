// turns a button into a link-like thing
@mixin button-to-anchor() {
  display: inline;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

// code for the internal subnav in Attorneys or Practice Areas
@mixin internalSubNav() {
  padding-bottom: 20px;
  padding-right: 15px;
  // max-height: $wrapperHeight;
  overflow: auto;
  @include for-breakpoint(6) {
    text-align: right;
    max-height: $wrapperHeight;
  }

  > ul {
    padding-top: 20px;
    @include for-breakpoint(6) {
      padding-top: 15px;
    }

    > li {
      display: block;

      > a {
        display: inline-block;
        text-decoration: none;
        color: $color2;
        @include rem("font-size", 19px);
        @include rem("line-height", 21px);
        text-transform: uppercase;
        margin-left: $mobile-margin;
        margin-bottom: 15px;
        border-bottom-width: $underlineWidth;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        transition: border-color $transTime ease, color $transTime ease;
        @include for-breakpoint(6) {
          display: block;
          @include rem("font-size", 16px);
          @include rem("line-height", 17px);
          padding-bottom: 1px;
          margin-left: 0;
        }

        &:hover {
          color: $color1;
          border-bottom-color: $color1;
        }
      }
    }
  }
  > ul + ul {
    margin-top: 0px;
    border-top: 1px solid lighten($color2, 20%);
  }
}
// code for the internal nav title and button
@mixin internalNav($order: 0) {
  display: block;
  flex-basis: 100%;
  order: $order;
  // height: 54px;
  @include for-breakpoint(6) {
    display: none;
  }

  > a,
  > h2 {
    display: block;
    padding: 16px $mobile-margin;
    border-color: $color1;
    border-style: solid;
    border-top-width: 2px;
    @include rem("font-size", 18px);
    @include rem("line-height", 18px);
    text-transform: uppercase;
    color: $color1;
    text-decoration: none;
    border-bottom-width: 2px;
  }

  > a {
    transition: color $transTime ease;

    &:hover {
      color: $color2;
    }
  }

  &:last-child {
    > a,
    > h2 {
      border-bottom-width: 2px;
    }
  }
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
/// https://css-tricks.com/snippets/sass/css-triangle-mixin/
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  display: inline-block;
  border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    "top": "bottom",
    "right": "left",
    "bottom": "top",
    "left": "right",
    "center": "center",
    "ltr": "rtl",
    "rtl": "ltr",
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append(
        $opposite-directions,
        unquote(map-get($direction-map, $direction))
      );
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}
