@mixin hasSelectedArea($area) {
  &.hasSelected#{$area} {
    > ul > li.#{to-lower-case($area)} > a {
      color: $color1;
      border-bottom-color: $color1;
    }
  }
}

.u_areas-subnav {
  @include internalSubNav();
  @include hasSelectedArea("Art");
  @include hasSelectedArea("Litigation");
  @include hasSelectedArea("Bankruptcy");
  @include hasSelectedArea("Ownership");
  @include hasSelectedArea("GovLit");
  @include hasSelectedArea("Intel");
  @include hasSelectedArea("Securities");
}
