.u_main-footer {
  > div.wrapper {
    display: block;
    max-width: $max-width;
    margin: 25px auto;
    padding: 0px $desktop-margin;
    > a.news-item {
      display: block;
      text-decoration: none;
      > span {
        text-transform: uppercase;
        @include rem("font-size", 17px);
        line-height: 1em;
        display: inline-block;
      }
      > span.news-title {
        color: $color1;
        margin-right: 10px;
      }
      > span.news-source {
        font-style: italic;
        &:after {
          content: ",";
        }
      }
      > span.news-source,
      > span.news-date {
        color: $color2;
      }
    }
  }
  &.isHomepage {
    @include for-breakpoint(6) {
      opacity: 0;
      transition: opacity $uncoverTransition ease;
      transition-delay: $animationTransition;
    }
  }
  &.isUncovered {
    @include for-breakpoint(6) {
      opacity: 1;
    }
  }
  &.isAnimationSkipped {
    @include for-breakpoint(6) {
      transition-delay: 0ms;
      transition-duration: 0ms;
    }
  }
}
