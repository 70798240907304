$spaceBetween: 5px;
$barHeight: 3px;
$barWidth: 30px;

.u_mobile-nav-btn {
  position: relative;
  @include button-to-anchor();
  width: $barWidth;
  height: ($spaceBetween + $barHeight)*2 + $barHeight;
  > span.alt-text {
    display: none;
  }
  > span {
    width: 100%;
    height: $barHeight;
    background-color: $color1;
    display: block;
    position: absolute;
    &.top {
      top: 0px;
    }
    &.mid1, &.mid2 {
      top: $spaceBetween + $barHeight;
    }
    &.bottom {
      top: ($spaceBetween + $barHeight)*2;
    }
  }
}
